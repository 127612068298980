import NavBar from "./DefaultHeader";
import {
  AssignmentDetail,
  DuePhase,
  useAssignmentDetailQuery,
  useAssignmentSubmitQuery,
} from "@/lib/assignmentDetails";
import {
  Button,
  VStack,
  StackDivider,
  Box,
  Stack,
  IconButton,
  useToast,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import {
  FileUpload,
  FileUploadTrigger,
  FileUploadDropzone,
} from "@saas-ui/file-upload";
import { useUserQuery } from "@/lib/user";
import { useWindowDimensions } from "@/lib/windowSize";
import {
  alertError,
  LinearPendingElement,
  loadingFrame,
  QuestionMarkElement,
  useAlertError,
} from "./LoadingFrame";
import { useParams } from "react-router-dom";
import { useExtensionLateDayApply } from "@/lib/extension";
import { RepeatIcon } from "@chakra-ui/icons";
import { useQueryClient } from "@tanstack/react-query";
import { formatDate } from "@/lib/datetime";
import { usePretend } from "@/lib/pretend";
import { duePhasePrompt } from "@/lib/assignmentDetails";

function AssignmentSubmitLateDayButton({
  assignment,
}: {
  assignment: AssignmentDetail;
}) {
  const submitDisclosure = useDisclosure();
  const lateDayDisclosure = useDisclosure();
  const useLateDayApply = useExtensionLateDayApply();
  useAlertError(useLateDayApply);
  const queryClient = useQueryClient();
  const { assignmentId } = useParams();
  const { pretend } = usePretend();
  const submitEnabled =
    assignment.dueState.duePhase === DuePhase.CAN_SUBMIT ||
    assignment.dueState.duePhase === DuePhase.PENALTY;
  const lateDayEnabled = assignment.dueState.duePhase === DuePhase.USE_LATE_DAY;
  return (
    <>
      <Stack direction={["column", "row"]} width="100%">
        <Tooltip
          label={
            submitEnabled ? "" : duePhasePrompt(assignment.dueState.duePhase)
          }
        >
          <Button
            colorScheme="teal"
            onClick={submitDisclosure.onOpen}
            isDisabled={!submitEnabled}
          >
            Submit {pretend === null ? "" : "AS " + pretend}
          </Button>
        </Tooltip>
        <Tooltip
          label={
            lateDayEnabled ? "" : duePhasePrompt(assignment.dueState.duePhase)
          }
        >
          <Button
            colorScheme="gray"
            onClick={lateDayDisclosure.onOpen}
            isDisabled={!lateDayEnabled}
          >
            Late Day
          </Button>
        </Tooltip>
        <IconButton
          aria-label="refresh assignment"
          icon={<RepeatIcon />}
          onClick={() => {
            queryClient.invalidateQueries({
              queryKey: ["user", "assignmentDetail", assignmentId],
            });
            queryClient.invalidateQueries({
              queryKey: ["user", "account"],
            });
          }}
        />
      </Stack>
      <Modal
        isOpen={submitDisclosure.isOpen}
        onClose={submitDisclosure.onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {assignment.dueState.duePhase === DuePhase.PENALTY
              ? "Submit to Autograder with 50% penalty"
              : "Submit to Autograder"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FileUploadArea postSubmit={submitDisclosure.onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={lateDayDisclosure.isOpen}
        onClose={lateDayDisclosure.onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply to use late day</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Use {assignment.dueState.extensionNeeded} late days to submit today
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={useLateDayApply.isPending}
              onClick={() => {
                useLateDayApply.mutate(assignmentId || "");
                lateDayDisclosure.onClose();
              }}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function DueDateText({ assignment }: { assignment: AssignmentDetail }) {
  const acceptLateDay =
    assignment.assignment.hardClosingTime !== null
      ? "Accepts late submissions (50% penalty) until: " +
        formatDate(assignment.assignment.hardClosingTime)
      : "";
  if (assignment.dueDateWithExtension > assignment.assignment.closingTime) {
    return (
      <span className="font-semibold">
        Due Date: extended until {formatDate(assignment.dueDateWithExtension)}{" "}
        {acceptLateDay}
      </span>
    );
  }
  return (
    <span className="font-semibold">
      Due Date: {formatDate(assignment.dueDateWithExtension)} {acceptLateDay}
    </span>
  );
}

function AssignmentNavBarContent() {
  const { width } = useWindowDimensions();
  const { assignmentId } = useParams();
  const assignmentDetail = useAssignmentDetailQuery(assignmentId || "");
  const toast = useToast();
  const assignmentHeaderView = loadingFrame(
    assignmentDetail,
    <LinearPendingElement />,
    <QuestionMarkElement />,
    (err: Error) => alertError(toast, err),
    (assignment: AssignmentDetail) => (
      <>
        <Stack>
          <VStack divider={<StackDivider />} className="my-3 mx-2" align="left">
            <Box>
              <span className="text-xl font-semibold">
                {assignment.assignment.projectName}
              </span>
            </Box>
            <Box>
              <VStack align="left">
                <DueDateText assignment={assignment} />
                <span className="font-semibold">
                  Used Submissions (Today): {assignment.todayUsedSubmits} /{" "}
                  {assignment?.todayTotalSubmits}
                </span>
                <span className="font-semibold">
                  Late Days Remaining: {assignment.lateDaysLeft}
                </span>
                <AssignmentSubmitLateDayButton assignment={assignment} />
              </VStack>
            </Box>
          </VStack>
        </Stack>
      </>
    ),
  );

  return (
    <>
      <NavBar />
      <Box w={width} shadow="xl">
        {assignmentHeaderView}
      </Box>
    </>
  );
}

export default function AssignmentIncludedNavBar() {
  return <AssignmentNavBarContent />;
}

function sizeLimit() {
  return 1024 * 1024;
}

function fileSize(size: number) {
  const units = ["B", "KB", "MB", "GB", "TB"];
  for (let i = 0; i < units.length; i++) {
    const unit = units[i];
    if (size < 1024 || i == units.length - 1) {
      return size.toFixed(2) + " " + unit;
    }
    size = size / 1024;
  }
}

function fileTooLarge(size: number) {
  // 1MB
  return size > sizeLimit();
}

export function FileUploadArea({ postSubmit }: { postSubmit: () => void }) {
  const { assignmentId } = useParams();
  const assignmentSubmit = useAssignmentSubmitQuery(
    assignmentId || "undefined",
  );
  useAlertError(assignmentSubmit);
  if (assignmentId === undefined) {
    throw Error("Assignment ID not set");
  }
  const { pretend } = usePretend();
  return (
    <FileUpload maxFiles={1} accept="application/*">
      {({ files, deleteFile }) => (
        <FileUploadDropzone>
          <Text fontSize="sm">Drag your submission here:</Text>
          {!files?.length ? (
            <FileUploadTrigger as={Button}>Select file</FileUploadTrigger>
          ) : (
            <VStack>
              <Text fontSize="sm">
                {files[0].name} ({fileSize(files[0].size)} /{" "}
                {fileSize(sizeLimit())})
              </Text>
              <Tooltip
                label={
                  fileTooLarge(files[0].size) ? "Your file is too large." : ""
                }
              >
                <Button
                  colorScheme="teal"
                  isDisabled={fileTooLarge(files[0].size)}
                  onClick={() => {
                    assignmentSubmit.mutate(files[0]);
                    postSubmit();
                  }}
                  isLoading={assignmentSubmit.isPending}
                >
                  Submit {pretend === null ? "" : "AS " + pretend}
                </Button>
              </Tooltip>
              <Button
                colorScheme="teal"
                variant="outline"
                onClick={() => {
                  deleteFile(files[0]);
                }}
              >
                Clear
              </Button>
            </VStack>
          )}
        </FileUploadDropzone>
      )}
    </FileUpload>
  );
}
