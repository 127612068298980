import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  HStack,
  useColorMode,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Link,
  Text,
  Badge,
  Center,
  Spacer,
  useToast,
} from "@chakra-ui/react";

import { Button, IconButton } from "@chakra-ui/react";

import { HamburgerIcon, SunIcon, MoonIcon } from "@chakra-ui/icons";

import { CommandPalette as Command } from "@/components/Command";

import * as React from "react";

import { UserLevel, useUserLogout, useUserQuery } from "@/lib/user";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  alertError,
  loadingFrame,
  QuestionMarkElement,
  useAlertError,
} from "./LoadingFrame";
import { Assignment, useAssignmentQuery } from "@/lib/assignment";
import { LinkWithPretend, useCheckPretend, usePretend } from "@/lib/pretend";
import {
  adminButtonGroup,
  labButtons,
  LinkButton,
  LinkButtonGroup,
  projectButtons,
  queueButtonGroup,
} from "@/lib/navigation";
import { useNavigate } from "react-router-dom";

function menuButton(button: LinkButton) {
  return (
    <MenuItem as={LinkWithPretend} to={button.url} key={button.key}>
      {button.name}
    </MenuItem>
  );
}

function menuButtonGroup(buttonGroup: LinkButtonGroup) {
  return (
    <Menu key={buttonGroup.key}>
      <MenuButton px={4} py={2} mx={2}>
        {buttonGroup.name} <ChevronDownIcon />
      </MenuButton>
      <MenuList>{buttonGroup.buttons.map(menuButton)}</MenuList>
    </Menu>
  );
}

function drawerButton(button: LinkButton) {
  return (
    <Button
      className="w-full my-1"
      key={button.key}
      as={LinkWithPretend}
      to={button.url}
    >
      {button.name}
    </Button>
  );
}

function drawerButtonGroup(buttonGroup: LinkButtonGroup) {
  return (
    <AccordionItem key={buttonGroup.key}>
      <h2>
        <AccordionButton>
          <Box as="span" flex="1" textAlign="left">
            {buttonGroup.name}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel>{buttonGroup.buttons.map(drawerButton)}</AccordionPanel>
    </AccordionItem>
  );
}

function NavBarButtons() {
  const toast = useToast();
  const buttonView = loadingFrame(
    useAssignmentQuery(),
    <></>,
    <></>,
    (err: Error) => alertError(toast, err),
    (assignments: Array<Assignment>) => (
      <UserLevel
        notLogIn={<></>}
        student={
          <>
            {menuButtonGroup(projectButtons(assignments))}
            {menuButtonGroup(labButtons(assignments))}
            {menuButtonGroup(queueButtonGroup)}
          </>
        }
        staff={
          <>
            {menuButtonGroup(projectButtons(assignments))}
            {menuButtonGroup(labButtons(assignments))}
            {menuButtonGroup(queueButtonGroup)}
            {menuButtonGroup(adminButtonGroup)}
          </>
        }
      />
    ),
  );

  return <>{buttonView}</>;
}

function NavAccordin() {
  const toast = useToast();
  const buttonView = loadingFrame(
    useAssignmentQuery(),
    <></>,
    <QuestionMarkElement />,
    (err: Error) => alertError(toast, err),
    (assignments: Array<Assignment>) => (
      <UserLevel
        notLogIn={<></>}
        student={
          <>
            {drawerButtonGroup(projectButtons(assignments))}
            {drawerButtonGroup(labButtons(assignments))}
            {drawerButtonGroup(queueButtonGroup)}
          </>
        }
        staff={
          <>
            {drawerButtonGroup(projectButtons(assignments))}
            {drawerButtonGroup(labButtons(assignments))}
            {drawerButtonGroup(queueButtonGroup)}
            {drawerButtonGroup(adminButtonGroup)}
          </>
        }
      />
    ),
  );
  return <Accordion allowMultiple>{buttonView}</Accordion>;
}

function NavDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton aria-label="Menu" icon={<HamburgerIcon />} onClick={onOpen} />
      <Drawer isOpen={isOpen} placement="right" size="lg" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Navigate EECS 281 Autograder</DrawerHeader>

          <DrawerBody>
            <NavAccordin />
          </DrawerBody>
          <DrawerFooter>
            <HStack>
              <Button as={LinkWithPretend} to="/" onClick={onClose}>
                Home
              </Button>
              <Button as={LinkWithPretend} to="/about" onClick={onClose}>
                About
              </Button>
              <Command />
              <ColorModeToggleButton />
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

function Logo() {
  return (
    <Link as={LinkWithPretend} my="auto" to="/">
      <Text fontSize="x-large" fontWeight={8000}>
        EECS 281 Autograder
      </Text>
    </Link>
  );
}

function initiateLogin() {
  window.location.href = "/api/v1/auth/login";
}

function NavBarStudentAccountButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);
  const navigate = useNavigate();
  const { data: user } = useUserQuery();
  const logout = useUserLogout();
  useAlertError(logout);
  const logoutOnclick = () => {
    // console.log("logout onclick");
    logout.mutate();
    onClose();
    navigate("/");
  };
  if (user === null || user === undefined) {
    return (
      <Button colorScheme="teal" onClick={initiateLogin}>
        Login
      </Button>
    );
  } else {
    return (
      <div>
        <Button colorScheme="teal" onClick={onOpen}>
          {user.uniqname}
        </Button>
        <AlertDialog
          isOpen={isOpen}
          onClose={onClose}
          leastDestructiveRef={cancelRef}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Account Information
              </AlertDialogHeader>

              <AlertDialogBody>
                <Text>Uniqname: {user.uniqname}</Text>
                <Text>Late Days Remaining: {user.lateDaysLeft}</Text>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button onClick={onClose} ref={cancelRef}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={logoutOnclick} ml={3}>
                  Log Out
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </div>
    );
  }
}

function ColorModeToggleButton() {
  const { colorMode, toggleColorMode } = useColorMode();
  const icon = colorMode === "light" ? <MoonIcon /> : <SunIcon />;
  return (
    <IconButton
      onClick={toggleColorMode}
      aria-label="Toggle Color Mode"
      icon={icon}
    />
  );
}

function PretendBadge() {
  const { pretend } = usePretend();
  // console.log(pretend);
  return (
    <>
      {pretend !== null ? (
        <Badge my="auto" colorScheme="red" mx={3}>
          As: {pretend}
        </Badge>
      ) : (
        <></>
      )}
    </>
  );
}

export default function NavBar() {
  useCheckPretend();
  return (
    <div className="flex py-3 bg-inherit mx-3">
      <div className="hidden xl:block my-auto">
        <Logo />
      </div>
      <PretendBadge />
      <Spacer />
      <Center>
        <div className="hidden lg:block">
          <NavBarButtons />
        </div>
      </Center>
      <Spacer />
      <HStack>
        <div className="hidden lg:block">
          <Command />
        </div>

        <Button as={LinkWithPretend} to="/faq">
          FAQ
        </Button>
        <div className="hidden lg:block">
          <Button as={LinkWithPretend} to="/about">
            About
          </Button>
        </div>
        <div className="hidden lg:block">
          <ColorModeToggleButton />
        </div>
        <NavBarStudentAccountButton />
        <div className="lg:hidden">
          <NavDrawer />
        </div>
      </HStack>
    </div>
  );
}
