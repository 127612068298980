import { useColorMode } from "@chakra-ui/react";

export function useLinkStyle(): React.CSSProperties {
  const { colorMode } = useColorMode();
  if (colorMode === "dark") {
    return {
      textDecoration: "underline",
      color: "var(--chakra-colors-blue-400)",
    };
  } else {
    return {
      textDecoration: "underline",
      color: "var(--chakra-colors-blue-600)",
    };
  }
}
