import {
  alertError,
  CircularPendingElement,
  loadingFrame,
  QuestionMarkElement,
} from "@/components/LoadingFrame";
import SlimContainer from "@/components/SlimContainer";
import { useQueueQuery } from "@/lib/queue";
import {
  Text,
  VStack,
  CardBody,
  Card,
  Center,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

type QueueObject = {
  uniqname: string;
  projectId: string;
  timestamp: string;
};

function viewQueryObject(item: QueueObject) {
  return (
    <Card minW="70%">
      <CardBody>
        <Text
          fontWeight={500}
          fontSize="large"
          key={item.uniqname + item.timestamp}
          align="center"
        >
          {item.uniqname}
        </Text>
        <Text
          fontWeight={200}
          fontSize="large"
          key={item.uniqname + item.timestamp}
          align="center"
        >
          {item.projectId}
        </Text>
        <Text
          fontWeight={200}
          fontSize="large"
          key={item.uniqname + item.timestamp}
          align="center"
        >
          {item.timestamp}
        </Text>
      </CardBody>
    </Card>
  );
}

export function Queue() {
  const { queueName } = useParams();
  const queueQuery = useQueueQuery(queueName || "UNDEFINED");
  const displayQueueName =
    queueName === "gradingQueue"
      ? "Grading Queue"
      : queueName === "finalGradingQueue"
        ? "Final Grading Queue"
        : "UNDEFINED";
  const toast = useToast();

  const queueView = loadingFrame(
    queueQuery,
    <CircularPendingElement />,
    <QuestionMarkElement />,
    (err: Error) => alertError(toast, err),
    (queueList: Array<QueueObject>) => (
      <VStack width="100%">
        {queueList.length === 0 ? (
          <Center>
            <Text fontSize="xx-large" color="gray">
              Queue Empty
            </Text>
          </Center>
        ) : (
          queueList?.map(viewQueryObject)
        )}
      </VStack>
    ),
  );
  return (
    <SlimContainer>
      <Text fontSize="xxx-large" align="center">
        {displayQueueName}
      </Text>
      {queueView}
    </SlimContainer>
  );
}
