import { useParams } from "react-router-dom";
import {
  RunResult,
  Submission,
  Test,
  runResultCellSetup,
  useAssignmentDetailQuery,
  AssignmentDetail,
} from "@/lib/assignmentDetails";
import "./AssignmentTable.css";
import {
  Box,
  Center,
  Link,
  Text,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { useWindowDimensions } from "@/lib/windowSize";
import {
  alertError,
  CircularPendingElement,
  loadingFrame,
  QuestionMarkElement,
} from "@/components/LoadingFrame";
import { isInstructor, User, useUserQuery } from "@/lib/user";
import { LinkWithPretend } from "@/lib/pretend";
import React from "react";
import { useLinkStyle } from "@/lib/linkStyle";

const style: React.CSSProperties = {
  background: "var(--chakra-colors-chakra-body-bg)",
};

const linkStyleNoColor: React.CSSProperties = {
  textDecoration: "underline",
};

function SubmissionMetaInfo({ submission }: { submission: Submission }) {
  const score = submission.totalPoints;
  const bugsCaught = submission.buggySols;
  return (
    <>
      <td style={style} key={submission.timestamp + "score"}>
        {score.toFixed(2)}
      </td>
      <td style={style} key={submission.timestamp + "buggysols"}>
        {bugsCaught}
      </td>
    </>
  );
}

function SubmissionLine({
  submission,
  runResults,
  tests,
}: {
  submission: Submission;
  runResults: Array<RunResult>;
  tests: Array<Test>;
}) {
  const { colorMode } = useColorMode();
  const { data: user } = useUserQuery();
  const submissionRunResults = runResults.filter(
    (runResult: RunResult) => runResult.timestamp === submission.timestamp,
  );
  const line = tests.map(
    (test: Test) =>
      [
        submissionRunResults.find(
          (element: RunResult) => element.testId === test.testId,
        ),
        test,
      ] as [RunResult | undefined, Test],
  );
  return (
    <>
      {line.map(
        ([result, test]: [RunResult | undefined, Test], idx: number) => {
          if (result === undefined) {
            return <td key={submission.timestamp + idx}>N/A</td>;
          } else {
            const cellSetup = runResultCellSetup(result, test, colorMode);
            // console.log(cellSetup);
            const url =
              "/test/" +
              test.projectId +
              "/" +
              submission.timestamp +
              "/" +
              submission.destServer +
              "/" +
              test.testId;
            return (
              <td
                key={submission.timestamp + idx}
                style={{ backgroundColor: cellSetup.background }}
              >
                {isInstructor(user) ? (
                  <Link as={LinkWithPretend} to={url} style={linkStyleNoColor}>
                    <Text>{cellSetup.text}</Text>
                  </Link>
                ) : (
                  cellSetup.text
                )}
              </td>
            );
          }
        },
      )}
    </>
  );
}

function testTableHeader(test: Test) {
  return <th key={test.testId + "name"}>{test.testId}</th>;
}

function SubmissionHead({
  isInstructor,
  assignmentId,
  submissionTimestamp,
  isGraded,
  isExtra,
  destServer,
}: {
  isInstructor: boolean;
  assignmentId: string | undefined;
  submissionTimestamp: string;
  isGraded: number;
  isExtra: boolean;
  destServer: string;
}) {
  const available = isGraded === 1 || (isGraded === 2 && isInstructor);
  const linkStyle = useLinkStyle();
  if (assignmentId === undefined) {
    return <></>;
  }
  const tag =
    isGraded === 0
      ? "(queued)"
      : isGraded === 1
        ? ""
        : isGraded === 2
          ? "(grading)"
          : "(undefined)";
  const name =
    (isExtra ? submissionTimestamp + "*" : submissionTimestamp) + " " + tag;
  if (!available) {
    return <>{name}</>;
  }
  const destination =
    "/file/" +
    assignmentId +
    "/" +
    submissionTimestamp +
    "/" +
    destServer +
    "/run_out";
  return (
    <Link to={destination} as={LinkWithPretend} style={linkStyle}>
      <Text>{name}</Text>
    </Link>
  );
}
const legends = [
  {
    name: "Timestamp*",
    content: "Submission didn't count toward your daily limit.",
  },
  { name: "N/A", content: "Not available: the test didn't run." },
  {
    name: "WA",
    content: "Wrong Answer: your answer was incorrect or incomplete.",
  },
  {
    name: "TLE",
    content:
      "Time Limit Exceeded: your program exceeded the time limit for this testcase.",
  },
  {
    name: "SIG",
    content:
      "Your program encountered an error (segfault, assertion failure, ran out of memory, etc.) and exited.",
  },
];

// const legend = <Box margin={3}><Text><Text fontWeight={600} as="span">Timestamp*:{" "}</Text>Submission didn't count toward your daily submission.</Text></Box>
const legendView = (
  <Box margin={3}>
    {legends.map((legend) => (
      <Text style={{ whiteSpace: "nowrap" }}>
        <Text fontWeight={600} as="span">
          {legend.name}
          {": "}
        </Text>
        {legend.content}
      </Text>
    ))}
  </Box>
);

export default function Assignment() {
  const { assignmentId } = useParams();

  const windowDimensions = useWindowDimensions();
  const toast = useToast();
  const { data: user } = useUserQuery();
  const assignmentView = loadingFrame(
    useAssignmentDetailQuery(assignmentId || ""),
    <CircularPendingElement />,
    <QuestionMarkElement />,
    (err: Error) => alertError(toast, err),
    (assignmentDetails: AssignmentDetail) => {
      const hiddenTests = assignmentDetails.tests.filter((test: Test) =>
        test.testId.startsWith("_"),
      );
      const nonHiddenTests = assignmentDetails.tests.filter(
        (test: Test) => !test.testId.startsWith("_"),
      );
      const tests = nonHiddenTests.concat(hiddenTests);
      return (
        <>
          <table className="eecs281table">
            <thead>
              <tr>
                <th style={style}>Timestamp</th>
                <th>Score</th>
                <th>Bugs Caught</th>
                {tests.map(testTableHeader)}
              </tr>
            </thead>
            <tbody>
              {assignmentDetails.submissions.map((submission: Submission) => (
                <tr key={submission.timestamp + "bundle"}>
                  <th style={style} key={submission.timestamp + "head"}>
                    <SubmissionHead
                      isInstructor={isInstructor(user)}
                      assignmentId={assignmentId}
                      submissionTimestamp={submission.timestamp}
                      isGraded={submission.isGraded}
                      isExtra={submission.isExtra}
                      destServer={submission.destServer}
                    />
                  </th>
                  <SubmissionMetaInfo submission={submission} />
                  <SubmissionLine
                    submission={submission}
                    runResults={assignmentDetails?.runResults}
                    tests={tests}
                  />
                </tr>
              ))}
            </tbody>
          </table>
          {assignmentDetails === null ||
          assignmentDetails?.submissions.length === 0 ? (
            <Center className="fixed" w={windowDimensions.width} h={200}>
              <Text fontSize="xxx-large" color="gray">
                {" "}
                No Available Data{" "}
              </Text>
            </Center>
          ) : (
            <>{legendView}</>
          )}
        </>
      );
    },
  );

  return <>{assignmentView}</>;
}
